import React from 'react';
import { motion } from 'framer-motion';
import './Biography.css';

const Biography: React.FC = () => {
	return (
		<div className="biography-container">
			<img
				src={'/group.jpeg'}
				alt="Matty & The Penders"
				className="biography-photo"
			/>
			<h1 className="biography-title">About Us</h1>
			<motion.div
				className="biography-content"
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				transition={{ duration: 1 }}>
				<div className="highlight">
					A FEW DADS FROM NORTHWOOD AND ONE FROM BARRINGTON
				</div>
				<p>
					In the pre-pandemic days of early 2020, while sitting at a bar, one
					Mike text messaged the other Mike saying “I want to start a 90s
					alternative rock cover band with you” - which was the start of this
					epic journey.
				</p>
				<p>
					In late February of 2020, 3 dads from Northwood, New Hampshire (Mike,
					Mike, and Aaron), teamed up with another dad from Barrington, New
					Hampshire (Matty) to do just that - and “Matty & The Penders” was
					born.
				</p>
				<p>
					The mission was simple - to specialize in playing a large variety of
					90s alternative rock music, and bring fans back to the decade of
					nostalgic alternative rock by covering songs with raw talent, emotion,
					and grit.
				</p>
				<p>
					Matty & The Penders cover songs by your favorite 90s rock bands,
					including Oasis, Matchbox 20, Everclear, The Black Crowes, Counting
					Crows, Candlebox, Barenaked Ladies, Blink 182, Live, Sublime, The
					Wallflowers, Green Day, Hootie & The Blowfish, and much, much more.
				</p>
				<p>
					With lots of practice, an amazing band chemistry, and a unique
					camaraderie, Matty & The Penders is one of the hottest up-and-coming
					cover bands - currently rocking all over New Hampshire and southern
					Maine.
				</p>
				<div className="band-names">
					<p>Matty - Lead Vocals and Acoustic Guitar</p>
					<p>Mike - Lead Guitar & Backing Vocals</p>
					<p>Mike - Bass & Backing Vocals</p>
					<p>Aaron - Drums</p>
				</div>
			</motion.div>
		</div>
	);
};

export default Biography;
