import React from 'react';
import { motion } from 'framer-motion';
import './Home.css'; // Import the CSS file for styling

const Home: React.FC = () => {
	return (
		<div className="home-container">
			<motion.div
				className="logo-container"
				initial={{ opacity: 0, scale: 0.6 }}
				animate={{ opacity: 1, scale: 1 }}
				transition={{ duration: 1.5, ease: 'easeOut' }}>
				<motion.img
					src={'/matty-logo.png'}
					alt="Band Logo"
					className="band-logo"
					whileHover={{ scale: 1.05 }}
					whileTap={{ scale: 0.95 }}
				/>
			</motion.div>
			<motion.div
				className="background-animation"
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				transition={{ duration: 2, delay: 1 }}>
				<div className="stars"></div>
			</motion.div>
		</div>
	);
};

export default Home;
