import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import ShowDates from './pages/ShowDates';
import Biography from './pages/Biography';
import Navbar from './components/Navbar';
import './App.css';
import Footer from './components/Footer';

const App: React.FC = () => {
	return (
		<Router>
			<Navbar />
			<div className="content">
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/shows" element={<ShowDates />} />
					<Route path="/biography" element={<Biography />} />
				</Routes>
			</div>
			<Footer />
		</Router>
	);
};

export default App;
