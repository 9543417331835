import React, { useState } from 'react';
import './Navbar.css'; // Make sure this file includes the updated styles

const NavBar: React.FC = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	return (
		<nav className="nav-bar">
			<div className="hamburger" onClick={toggleMenu}>
				☰
			</div>
			<ul className={isMenuOpen ? 'active' : ''}>
				<li>
					<a href="/">Home</a>
				</li>
				<li>
					<a href="/shows">Shows</a>
				</li>
				<li>
					<a href="/biography">Biography</a>
				</li>
			</ul>
		</nav>
	);
};

export default NavBar;
