import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFacebookF,
	faInstagram,
	faYoutube,
} from '@fortawesome/free-brands-svg-icons';

import './Footer.css'; // Make sure to create a CSS file for styling

const Footer = () => {
	return (
		<footer className="footer">
			<div className="footer-container">
				<div className="footer-social-icons">
					<a
						href="https://www.facebook.com/mattyandthependers/"
						target="_blank"
						rel="noopener noreferrer"
						className="social-icon">
						<FontAwesomeIcon icon={faFacebookF} />
					</a>
					<a
						href="https://www.instagram.com/mattyandthependers/"
						target="_blank"
						rel="noopener noreferrer"
						className="social-icon">
						<FontAwesomeIcon icon={faInstagram} />
					</a>
					<a
						href="https://www.youtube.com/channel/UCh0kBRVfPDrDf-0nAK6FBvw"
						target="_blank"
						rel="noopener noreferrer"
						className="social-icon">
						<FontAwesomeIcon icon={faYoutube} />
					</a>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
